<template>
  <v-app>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center" class="mt-16">
        <v-col cols="12" md="4">
          <base-material-card>
            <template v-slot:heading>
              <v-img src="logo.png" max-width="200" class="float-right" />
              <div class="text-h3 font-weight-light mt-5">Sign in</div>
            </template>
            <v-form ref="form">
              <v-container class="py-2">
                <v-alert
                  color="red lighten-2"
                  dark
                  v-if="showError"
                  dismissible
                >
                  {{ error }}
                </v-alert>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Email Address"
                      class="purple-input"
                      v-model="email"
                      :rules="emailRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="password"
                      label="Password"
                      hint="At least 6 characters"
                      :type="show1 ? 'text' : 'password'"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      counter
                      @click:append="show1 = !show1"
                      :rules="passwordRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mr-0"
                      @click="forgotPassword"
                    >
                      Forgot Password
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      type="submit"
                      color="primary"
                      class="mr-0"
                      @click.prevent="loginData"
                      :loading="loading"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      error: "Email or Password is incorrect",
      showError: false,
      show1: false,
      emailRule: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      passwordRule: [v => v.length >= 6 || "Minimum length is 6 characters"],
      loading: false
    };
  },
  methods: {
    loginData() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          email: this.email,
          password: this.password,
          firebase_token: sessionStorage.getItem("firebase_token")
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "login", data)
          .then(response => {
            if (response.status == 200) {
              // redirect
              let token = response.data.token;
              let permission = JSON.stringify(response.data.roles);
              sessionStorage.setItem("time", response.data.time);
              sessionStorage.setItem("user-token", token);
              sessionStorage.setItem("user-permission", permission);
              sessionStorage.setItem(
                "user_type_id",
                response.data.user_type_id
              );
              sessionStorage.removeItem("firebase_token");
              this.loading = false;
              this.$router.push({ name: "Dashboard" });
            }
          })
          .catch(error => {
            this.loading = false;
            this.showError = true;
          });
      }
    },
    forgotPassword() {
      this.$router.push({ name: "Forgot Password" });
    },
    isReadyTologin() {
      return sessionStorage.getItem("firebase_token") == undefined ||
        sessionStorage.getItem("firebase_token") === ""
        ? true
        : false;
    }
  }
};
</script>
<style></style>
