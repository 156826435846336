// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// import { register } from "register-service-worker";

// register("/firebase-messaging.js", {
//   registrationOptions: { scope: "/" },
//   ready(registration) {
//     console.log(registration);
//     console.log("Service worker is active.");
//   },
//   registered(registration) {
//     console.log("Service worker has been registered.");
//   },
//   cached(registration) {
//     console.log("Content has been cached for offline use.");
//   },
//   updatefound(registration) {
//     console.log("New content is downloading.");
//   },
//   updated(registration) {
//     console.log("New content is available; please refresh.");
//   },
//   offline() {
//     console.log(
//       "No internet connection found. App is running in offline mode."
//     );
//   },
//   error(error) {
//     console.error("Error during service worker registration:", error);
//   }
// });
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

window.axios = require("axios");
