<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="error"
          title="Page Not Found"
          class="px-5 py-3"
        >
          <v-card-text class="px-0 pb-0">
            <v-img src="page_not_found.png" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Page Not Found</div>
          </template>
        </base-material-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>