<template>
  <v-app>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center" class="mt-16">
        <v-col cols="12" md="4">
          <base-material-card>
            <template v-slot:heading>
              <v-img src="logo.png" max-width="180" class="float-right" />
              <div class="text-h3 font-weight-light mt-5">Forgot Password</div>
            </template>
            <v-form ref="form">
              <v-container class="py-2">
                <v-alert
                  color="red lighten-2"
                  dark
                  v-if="showError"
                  dismissible
                >
                  {{ errorMessage }}
                </v-alert>
                <v-alert
                  color="green lighten-2"
                  dark
                  v-if="showSuccess"
                  dismissible
                >
                  {{ successMessage }}
                </v-alert>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Email Address"
                      class="purple-input"
                      v-model="email"
                      :rules="emailRule"
                    />
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mr-0"
                      @click="sendMail"
                      :loading="loading"
                    >
                      Send Password Reset Link
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mr-0"
                      @click="login"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showError: false,
      showSuccess: false,
      email: "",
      errorMessage: "",
      successMessage: "",
      emailRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      loading: false,
    };
  },
  methods: {
    sendMail() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = { email: this.email };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "forgotPassword", data)
          .then((response) => {
            if (response.status == 200) {
                this.loading = false;
                this.showSuccess = true;
                this.successMessage = response.data.message
            }
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message
            this.loading = false;
            this.showError = true;
          });
      }
    },
    login(){
        this.$router.push({ name: "Login" });
    }
  },
};
</script>