<template>
  <div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
};

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Howl, Howler } from "howler";
import { commonService } from "./views/dashboard/services/common.service";

const firebaseConfig = {
  apiKey: "AIzaSyAPAFBtr77qeMWtCaMW1cIsfMYkA4-BXEs",
  authDomain: "gluggler-63729.firebaseapp.com",
  projectId: "gluggler-63729",
  storageBucket: "gluggler-63729.appspot.com",
  messagingSenderId: "496459460502",
  appId: "1:496459460502:web:b53b0a2acee6216b6937b7",
  measurementId: "G-5QS9LS5FQT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);

  if (payload.data.eventType == "NewOrder") {
    commonService.sendEvent("refreshOrders");
  }
  if (payload.data.eventType == "NewTicket") {
    commonService.sendEvent("refreshTickets");
  }
  if (payload.data.eventType == "NewMessage") {
    commonService.sendEvent("refreshMessages");
  }

  // var sound = new Howl({
  //   src: ["fanfare.mp3"],
  // });
  var sound = new Howl({
    src: ["fanfare.mp3"],
    autoplay: true,
    volume: 0.5,
    onend: function () {
      console.log("Finished!");
    },
  });
  sound.play();
  // ...
});

// "BMT_sbuE9dD1qi334hY9O5fWGLOcGFCpz6ti9EyrIqQECH8zLjhEvdVX2HtP_4eze5JBcGrKsZs4sxE8tk4c-lo",
getToken(messaging, {
  vapidKey:
    "BMGspgpH4BIZ4T93tn3xDOuW6QfuWqssl5w_N6mr4OTk5WAIYr8HSgAblIOGN2W4dRjldNWY0vGmFndJlXRWVnA",
})
  .then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      console.log("Token is:", currentToken);
      sessionStorage.setItem("firebase_token", currentToken);

      // ...
    } else {
      // Show permission request UI
      console.log("No registration token available. Request permission to generate one.");
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });
</script>
<style>
table thead th {
  font-size: 18px !important;
}
a {
  text-decoration: none;
}
.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
